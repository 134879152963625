import { result, isArray } from 'lodash';
import { format as formatDate } from 'date-fns';

import ModalButton from './ModalButton';

export default function Activity({ activity, activityTypes }) {
  const { id, type, createdBy, createdAt, payload } = activity;
  const { note, body, messageBody, attachedFiles } = payload || {};

  return (
    <div key={id} className="d-flex flex-column gap-1">
      <div className="d-flex gap-1 align-items-center">
        <div className="text-nowrap" style={{ width: '100px' }}>
          {result(activityTypes, [type, 'text'])}
        </div>
        <div className="d-flex gap-2 text-nowrap">
          <div className="small text-muted">by {createdBy?.displayName}</div>
          <div className="small text-muted">{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}</div>
        </div>
        {body && (
          <ModalButton
            label="内容確認"
            title="不具合問い合わせの返信メール内容"
            outline
            color="info"
            className="ml-2"
            size="sm"
            content={(_) => {
              return (
                <div>
                  <div>
                    <h5>メール本文</h5>
                    <div className="card p-3 mt-3" style={{ whiteSpace: 'pre-line' }}>
                      {body}
                    </div>
                  </div>
                  {
                    messageBody && (
                      <div className="mt-4">
                        <h5>メッセージ内容</h5>
                        <div className="card p-3" style={{ whiteSpace: 'pre-line', }}>
                          {messageBody}
                        </div>
                      </div>
                    )
                  }
                  {(attachedFiles || []).length > 0 && (
                    <div className="mt-3">
                      {attachedFiles?.map((_) => (
                        <div>
                          <a href={_.url} target="_blank">
                            {_.name}
                          </a>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            }}
          />
        )}
      </div>
      {note && (
        <>
          {isArray(note) ? (
            note.map((_) => (
              <div key={_} className="small text-muted text-break">
                {_}
              </div>
            ))
          ) : (
            <div className="small text-muted text-break">{note}</div>
          )}
        </>
      )}
    </div>
  );
}
