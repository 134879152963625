const { isEmpty } = require('lodash');

const paceMakerTypes = {
  order: { label: '注文/売上' },
  event: { label: 'イベント' },
};

const scheduleTypes = {
  fixedPeriod: { label: '期間指定' },
  monthlyRecurring: { label: '月間' },
};

const fields = ({ areaGroups = [] }) => {
  return {
    name: {
      label: '名前',
      type: 'string',
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
    type: {
      label: 'ペースメーカー種別',
      type: 'select',
      options: Object.entries(paceMakerTypes).map(([k, v]) => ({ label: v.label, value: k })),
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
    destinations: {
      label: '送信先メールアドレス',
      type: 'string',
      validations: {
        required: (_) => !isEmpty(_),
      },
      hint: 'カンマ区切りで複数指定できます',
    },
    targetDirectSalesVolume: {
      label: '目標直販台数',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
      hidden: (_) => _.type !== 'order',
    },
    targetDirectSalesAmount: {
      label: '目標直販金額',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
      hidden: (_) => _.type !== 'order',
    },
    targetAgentSalesVolume: {
      label: '目標代理店台数',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
      hidden: (_) => _.type !== 'order',
    },
    targetAgentSalesAmount: {
      label: '目標代理店金額',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
      hidden: (_) => _.type !== 'order',
    },
    targetHostingEventsCount: {
      label: '目標イベント開催数 - 責任者',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
      hidden: (_) => _.type !== 'event',
    },
    targetLecturesMainPicCount: {
      label: 'レクチャー回数 - メイン担当',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
      hidden: (_) => _.type !== 'event',
    },
    targetLecturesSubPicCount: {
      label: 'レクチャー回数 - サブ担当',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
      hidden: (_) => _.type !== 'event',
    },
    targetSurveyAnswersCount: {
      label: '目標イベント事後アンケート回答数 - 責任者',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
      hidden: (_) => _.type !== 'event',
    },
    targetSurveyAnswersMainPicCount: {
      label: '目標イベント事後アンケート回答数 - メイン担当',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
      hidden: (_) => _.type !== 'event',
    },
    targetSurveyAnswersSubPicCount: {
      label: '目標イベント事後アンケート回答数 - サブ担当',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
      hidden: (_) => _.type !== 'event',
    },
    targetSalesVolumeAfterEvent: {
      label: '目標イベント後販売数 - 責任者',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
      hidden: (_) => _.type !== 'event',
    },
    targetSalesVolumeMainPicAfterEvent: {
      label: '目標イベント後販売数 - メイン担当',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
      hidden: (_) => _.type !== 'event',
    },
    targetSalesVolumeSubPicAfterEvent: {
      label: '目標イベント後販売数 - サブ担当',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
      hidden: (_) => _.type !== 'event',
    },
    areaGroups: {
      label: 'エリアグループ',
      type: 'multiSelect',
      options: areaGroups.map((_) => ({ label: _, value: _ })),
      initialValue: [],
      hint: 'ORで判定します',
    },
    scheduleType: {
      label: 'スケジュール種別',
      type: 'select',
      options: Object.entries(scheduleTypes).map(([k, v]) => ({ label: v.label, value: k })),
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
    startDate: {
      label: '開始日',
      type: 'date',
      validations: {
        required: (_) => _,
      },
      hidden: (_) => _.scheduleType !== 'fixedPeriod',
    },
    endDate: {
      label: '終了日',
      type: 'date',
      validations: {
        required: (_) => _,
      },
      hidden: (_) => _.scheduleType !== 'fixedPeriod',
    },
  };
};

const targetFields = () => {
  return {
    category: {
      label: '商品カテゴリ',
      type: 'string',
      validations: {
        required: (_) => !isEmpty(_),
      },
      hint: 'HB,BB等で指定する',
    },
    targetDirectSalesVolume: {
      label: '目標直販台数',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
    },
    targetDirectSalesAmount: {
      label: '目標直販金額',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
    },
    targetAgentSalesVolume: {
      label: '目標代理店台数',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
    },
    targetAgentSalesAmount: {
      label: '目標代理店金額',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: (v) => v != null && v > 0,
      },
    },
  };
};

module.exports = {
  paceMakerTypes,
  fields,
  targetFields,
};
